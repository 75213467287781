import React, { useState, useEffect } from "react"
import { v4 as uuidv4 } from "uuid"
import crc32 from "crc/crc32"
import { dottedText } from "../../utility/general"
import ShowLoading from "../ShowLoading/ShowLoading"
import useRestrictCharacterLength from "../../hooks/useRestrictCharacterLength"
import thankq from "./../../images/thankq.png"
import errorImg from "./../../images/error.png"
import gift from "./../../images/giftbox.png"
import banner from "./../../images/voyaygebanner.jpg"
import is from "is_js"
import { analytics, functions } from "../firebase/firebase"
import "./ReferralProducer.scss"
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  PinterestShareButton,
  TelegramShareButton,
  TwitterShareButton,
  ViberShareButton,
  WhatsappShareButton,
  EmailIcon,
  FacebookIcon,
  FacebookMessengerIcon,
  HatenaIcon,
  InstapaperIcon,
  LineIcon,
  LinkedinIcon,
  LivejournalIcon,
  MailruIcon,
  OKIcon,
  PinterestIcon,
  PocketIcon,
  RedditIcon,
  TelegramIcon,
  TumblrIcon,
  TwitterIcon,
  ViberIcon,
  VKIcon,
  WeiboIcon,
  WhatsappIcon,
  WorkplaceIcon,
} from "react-share"
import { LazyLoadImage } from "react-lazy-load-image-component"
import SEO from "../seo"
const ReferralProducerBase = ({ iType }) => {
  const [recipientEmailAddress, setRecipientEmailAddress] = useState("")
  const [referralLink, setReferralLink] = useState("")
  const [showValue, setShowValue] = useState("")
  const [isError, setIsError] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const [isBusy, setIsBusy] = useState(true)
  const [submissionState, setSubmissionState] = useState("show-email")
  const [textLength, setTextLength] = useState(0)
  const [URL, setURL] = useState("https://voyayge.com")
  const [recipientEmailInvalid, setRecipientEmailInvalid] = useState(false)
  const [emailReferralMessage, setEmailReferralMessage] = useState(
    "I highly recommend giving voyay!ge's newsletter a read. An awesome bi-weekly newsletter delivers curated travel information covering the latest travel trends, unique destinations, and free travel itineraries. Give it a try and subscribe using my personal invite below!"
  )
  const [facebookReferralText, setFacebookReferralText] = useState(
    "I highly recommend giving voyay!ge's newsletter a read. An awesome newsletter delivers curated travel info on the latest trends, unique destinations & free travel itineraries. Try it out, subscribe using my personal invite below!"
  )
  const [senderEmail, setSenderEmail] = useState("")
  const [
    reachedMaxLength,
    maxCharactersAllowed,
    check,
  ] = useRestrictCharacterLength(1000)
  const [sendingMessage, setSendingMessage] = useState(false)
  useEffect(() => {
    // setReferralLink(generateInviteURL())
    try {
      const authUser = localStorage.getItem("authUser")
      let r = JSON.parse(authUser)
      setSenderEmail(r.email)
      // console.log()
    } catch (err) {}
  }, [])
  useEffect(() => {
    document.title = "Referral Rewards"
  }, [])

  const sendReferralEmail = () => {
    let data = {
      sender_email: senderEmail,
      receiver_email: recipientEmailAddress,
      email_message: emailReferralMessage,
      referral_url: referralLink,
    }
    var sendNewsletterReferralEmail = functions.httpsCallable(
      "sendNewsletterReferralEmail"
    )
    sendNewsletterReferralEmail(data)
      .then(function(result) {
        // Read result of the Cloud Function.
        setIsBusy(false)
        console.log({ result })
        setSendingMessage(false)
        if (result.data.success) {
          // window.scrollTo(0, 0)
          setSubmissionState("success")
        } else {
          // window.scrollTo(0, 0)
          setSubmissionState("error")
        }
      })
      .catch(err => {
        console.log(err)
        setIsBusy(false)
        setSendingMessage(false)
        // window.scrollTo(0, 0)
      })
  }

  useEffect(() => {
    console.log({ iType })
    if (iType === "newsletter") {
      let data = {}
      var getNewsLetterReferralURL = functions.httpsCallable(
        "getNewsletterReferralURL"
      )
      getNewsLetterReferralURL(data)
        .then(function(result) {
          // Read result of the Cloud Function.
          setIsBusy(false)
          console.log({ result })
          if (result.data.rId !== "") {
            setReferralLink(`https://voyayge.com/sn?rId=${result.data.rId}`)
          }
          // if (result.data.success) {
          //   window.scrollTo(0, 0)
          //   setSubmissionState("success")
          // } else {
          //   window.scrollTo(0, 0)
          //   setSubmissionState("error")
          // }
          // ...
        })
        .catch(err => {
          console.log(err)
          setIsBusy(false)
          window.scrollTo(0, 0)
        })
    } else {
      setIsBusy(false)
      setErrorMessage("Invalid Referral Type")
      setIsError(true)
    }
  }, [iType])

  useEffect(() => {
    if (referralLink !== "") setShowValue(referralLink)
  }, [referralLink])
  const generateInviteURL = () => {
    let type = crc32("newsletter").toString(16)
    let rUrl = `https://voyayge.com/apps/invite/${uuidv4()}/${type}`
    return rUrl
  }
  const validateEmailAndSend = () => {
    if (is.email(recipientEmailAddress.trim())) {
      setRecipientEmailInvalid(false)
      sendReferralEmail()
    } else {
      setRecipientEmailInvalid(true)
      setSendingMessage(false)
    }
  }

  const showRightScreen = () => {
    switch (submissionState) {
      case "show-email":
        return (
          <div style={{ maxWidth: "450px", margin: "0px auto" }}>
            <div className="field">
              <label className="label">To</label>
              <div className="control">
                <input
                  className="input"
                  type="email"
                  placeholder="recipient email address"
                  vale={recipientEmailAddress}
                  style={{ fontSize: "14px" }}
                  onChange={e => {
                    setRecipientEmailInvalid(false)
                    setRecipientEmailAddress(e.currentTarget.value)
                  }}
                ></input>
                {recipientEmailInvalid && (
                  <p className="help is-danger">
                    Please enter a valid email address
                  </p>
                )}
              </div>
            </div>
            <div className="field">
              <label className="label">From</label>
              <div className="control">
                <input
                  disabled
                  className="input noselect"
                  type="email"
                  value={senderEmail}
                  style={{ fontSize: "14px" }}
                  // placeholder="recipient email address"
                ></input>
              </div>
            </div>
            <div className="field">
              <textarea
                style={{ fontSize: "14px" }}
                onChange={e => {
                  setEmailReferralMessage(e.currentTarget.value)
                }}
                value={emailReferralMessage}
                rows="7"
                className="textarea"
                maxLength="200"
              ></textarea>
            </div>
            <div className="field" style={{ width: "100%" }}>
              <button
                className={`${
                  sendingMessage
                    ? "button is-warning is-loading"
                    : "button is-warning"
                }`}
                style={{ width: "100%" }}
                onClick={() => {
                  if (sendingMessage) return false
                  setSendingMessage(true)
                  validateEmailAndSend()
                }}
              >
                Send
              </button>
            </div>
          </div>
        )
        return
      case "show-facebook":
        return (
          <div className="social-share-icon">
            <div style={{ maxWidth: "400px", margin: "10px auto" }}>
              <LazyLoadImage
                src={banner}
                alt="voyayge company logo"
                effect="blur"
              />
            </div>
            <p className="ref-share-text">{facebookReferralText}</p>
            <div style={{ maxWidth: "200px", margin: "20px auto" }}>
              <FacebookShareButton
                quote={`${facebookReferralText}`}
                hashtag="#govoyayge"
                onClick={() => {
                  analytics.logEvent("referral_share_facebook")
                }}
                url={referralLink}
                style={{ width: "100%" }}
              >
                <button className="button is-warning" style={{ width: "100%" }}>
                  <span className="icon">
                    <i className="fab fa-facebook"></i>
                  </span>
                  <span>Share</span>
                </button>
              </FacebookShareButton>
            </div>
          </div>
        )
        return
      case "show-twitter":
        return (
          <div className="social-share-icon">
            <div style={{ maxWidth: "400px", margin: "10px auto" }}>
              <LazyLoadImage
                src={banner}
                alt="voyayge company logo"
                effect="blur"
              />
            </div>
            <p className="ref-share-text">{facebookReferralText}</p>
            <div style={{ maxWidth: "200px", margin: "20px auto" }}>
              <TwitterShareButton
                url={referralLink}
                onClick={() => {
                  analytics.logEvent("referral_share_twitter")
                }}
                // title="I 💛 voyay!ge. Get the yay! back to your vacation. Check it out!"
                title={`${facebookReferralText}`}
                via="voyayge"
                style={{ width: "100%" }}
                hashtags={["checkitout"]}
              >
                <button className="button is-warning" style={{ width: "100%" }}>
                  <span className="icon">
                    <i className="fab fa-twitter"></i>
                  </span>
                  <span>Tweet</span>
                </button>
              </TwitterShareButton>
            </div>
          </div>
        )
        return
      case "error":
        return (
          <>
            <div style={{ margin: "20px auto", maxWidth: "300px" }}>
              <img
                src={errorImg}
                alt="error!"
                style={{
                  padding: "10px",
                  // maxHeight: "300px",
                  width: "100%",
                }}
              />
            </div>
            <p
              style={{
                fontSize: "20px",
                margin: "15px auto",
                textAlign: "center",
                fontWeight: "500",
              }}
            >
              Uh oh!, there was an error submitting your recommendations. Please
              try again in sometime. Sorry! <br></br>{" "}
              <small>
                If the issue persists please email to{" "}
                <a href="mailto: support@voyayge.com">support@voyayge.com</a>
              </small>
            </p>
          </>
        )
        break
      case "success":
        return (
          <>
            <p
              style={{
                fontSize: "16px",
                margin: "60px auto",
                textAlign: "center",
                fontWeight: "400",
              }}
            >
              Once your friend subscribes to the our newsletter you will get
              your referral point.
            </p>
            <div style={{ margin: "0px auto", maxWidth: "300px" }}>
              <button
                onClick={() => {
                  setSubmissionState("show-email")
                }}
                className="button is-warning"
                style={{ width: "100%" }}
              >
                Share again
              </button>
            </div>
          </>
        )
        break

      default:
        break
    }
  }

  return (
    <>
      {isBusy ? (
        <ShowLoading />
      ) : (
        <>
          <div className="columns">
            <div className="column is-2-desktop is-1-tablet is-hidden-mobile"></div>
            <div className="column is-8-desktop is-10-tablet is-12-mobile">
              {errorMessage != "" ? (
                <div>
                  <p style={{}} className="ref-page-title-primary">
                    Invalid Referral Type
                  </p>
                </div>
              ) : (
                <>
                  <div>
                    <p style={{}} className="ref-page-title-primary">
                      {/* <p style={{}} className="ref-page-title-primary-1">
                        Newsletter
                      </p> */}
                      Referral Rewards
                      <span style={{ color: "#fec600" }}>.</span>
                      <img className="ref-image" src={gift}></img>
                    </p>
                    <p style={{}} className="ref-page-title-secondary">
                      Each friend that signs up will give you a chance to earn
                      more!
                    </p>
                  </div>
                  <div className="rewards-display-wrapper">
                    <nav className="level">
                      <div className="level-item has-text-centered">
                        <div>
                          <p className="heading">1 Referral</p>
                          <p className="ref-prize-title">10% off</p>
                          {/* <p
                            className="heading"
                            style={{ textTransform: "lowercase" }}
                          >
                            Onetime voyay!ge bespoke travel plan
                          </p> */}
                        </div>
                      </div>
                      {/* <div className="level-item has-text-centered">
                        <div>
                          <p className="heading">5 Referrals</p>
                          <p className="ref-prize-title">Stickers</p>
                        </div>
                      </div> */}
                      <div className="level-item has-text-centered">
                        <div>
                          <p className="heading">10 Referrals</p>
                          <p className="ref-prize-title">15% off</p>
                          {/* <p
                            className="heading"
                            style={{ textTransform: "lowercase" }}
                          >
                            onetime voyay!ge bespoke travel plan
                          </p> */}
                        </div>
                      </div>
                      <div className="level-item has-text-centered">
                        <div>
                          <p className="heading">20 Referrals</p>
                          <p className="ref-prize-title">25% off</p>
                          {/* <p
                            className="heading"
                            style={{ textTransform: "lowercase" }}
                          >
                            onetime voyay!ge bespoke travel plan
                          </p> */}
                        </div>
                      </div>
                      {/* <div className="level-item has-text-centered">
                        <div>
                          <p className="heading">20 Referrals</p>
                          <p className="ref-prize-title">Coffee Mug</p>
                          <p
                            className="heading"
                            style={{ textTransform: "lowercase" }}
                          >
                            *only available to those living in U.S.
                          </p>
                        </div>
                      </div> */}
                    </nav>
                    <p
                      className="heading"
                      style={{
                        textTransform: "lowercase",
                        textAlign: "center",
                      }}
                    >
                      *onetime discount on your voyay!ge bespoke travel plan on
                      voyayge.com and cannot be combined with any another other
                      offers.
                    </p>
                  </div>
                  <div
                    style={{
                      marginBottom: "25px",
                      maxWidth: "700px",
                      marginLeft: "auto",
                      marginRight: "auto",
                      marginTop: "65px",
                    }}
                  >
                    {/* <img
              src={logo}
              style={{
                width: "100%",
              }}
              onClick={() => {
                navigate("/")
              }}
            ></img> */}

                    <div className="tabs is-centered">
                      <ul>
                        <li
                          className={`ref-tab-item ${
                            submissionState === "show-email" ? "is-active" : ""
                          }`}
                        >
                          <a
                            onClick={e => {
                              setSubmissionState("show-email")
                            }}
                            className="ref-tab-item"
                          >
                            <span className="icon is-small">
                              <i
                                className="fas fa-envelope"
                                aria-hidden="true"
                              ></i>
                            </span>
                            <span>Email</span>
                          </a>
                        </li>
                        <li
                          className={`ref-tab-item ${
                            submissionState === "show-facebook"
                              ? "is-active"
                              : ""
                          }`}
                        >
                          <a
                            onClick={e => {
                              setSubmissionState("show-facebook")
                            }}
                          >
                            <span className="icon is-small">
                              <i
                                className="fab fa-facebook-f"
                                aria-hidden="true"
                              ></i>
                            </span>
                            <span>Facebook</span>
                          </a>
                        </li>
                        <li
                          className={`ref-tab-item ${
                            submissionState === "show-twitter"
                              ? "is-active"
                              : ""
                          }`}
                        >
                          <a
                            onClick={e => {
                              setSubmissionState("show-twitter")
                            }}
                          >
                            <span className="icon is-small">
                              <i
                                className="fab fa-twitter"
                                aria-hidden="true"
                              ></i>
                            </span>
                            <span>Twitter</span>
                          </a>
                        </li>
                        {/* <li>
                      <a>
                        <span className="icon is-small">
                          <i className="fab fa-linkedin-in" aria-hidden="true"></i>
                        </span>
                        <span>LinkedIn</span>
                      </a>
                    </li> */}
                        {/* <li>
                      <a>
                        <span className="icon is-small">
                          <i className="fab fa-whatsapp" aria-hidden="true"></i>
                        </span>
                        <span>Whatsapp</span>
                      </a>
                    </li> */}
                      </ul>
                    </div>
                    {showRightScreen()}
                  </div>
                  <div
                    className="text-separator"
                    style={{ maxWidth: "500px", margin: "40px auto" }}
                  >
                    OR
                  </div>
                  <div
                    className="ref-paste-anywhere"
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      alignItems: "baseline",
                      justifyContent: "center",
                    }}
                  >
                    <span
                      style={{
                        marginBottom: "7px",
                        marginRight: "10px",
                        fontWeight: "500",
                      }}
                    >
                      Paste your link anywhere:{" "}
                    </span>
                    <span
                      style={{
                        fontSize: "14px",
                        // marginLeft: "5px",
                        cursor: "pointer",
                        border: "1px solid #fec600",
                        borderRadius: "7px",
                        padding: "10px",
                        minWidth: "287px",
                        position: "relative",
                      }}
                      onClick={async () => {
                        await navigator.clipboard.writeText(referralLink)
                        setShowValue("Link Copied!")
                        setTimeout(() => {
                          setShowValue(referralLink)
                        }, 1000)
                      }}
                    >
                      {dottedText(showValue, 30)}
                      <span
                        style={{
                          position: "absolute",
                          fontSize: "14px",
                          marginLeft: "7px",
                          cursor: "pointer",
                          padding: "5px",
                          right: "7px",
                          top: "5px",
                        }}
                      >
                        <i className="far fa-copy fa-lg"></i>
                      </span>
                    </span>
                  </div>
                  <div
                    className="ref-paste-anywhere"
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      alignItems: "baseline",
                      justifyContent: "center",
                      marginTop: "35px",
                    }}
                  >
                    <a
                      href="/newsletter-referral-terms"
                      rel="nofollow"
                      style={{
                        textAlign: "right",
                        textTransform: "uppercase",
                        fontSize: "12px",
                        letterSpacing: "0.5px",
                      }}
                    >
                      View Terms
                    </a>
                  </div>
                </>
              )}
            </div>
            <div className="column is-2-desktop is-1-tablet is-hidden-mobile"></div>
          </div>
        </>
      )}
    </>
  )
}

const ReferralProducer = ({ iType }) => {
  return (
    <>
      <SEO
        pageTitle="Referral Rewards"
        description="Each friend that signs up will give you a chance to earn more!"
      />
      <ReferralProducerBase iType={iType} />
    </>
  )
}

export default ReferralProducer
